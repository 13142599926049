import React,{useState} from 'react'
import ReactPlayer from 'react-player'

import IconSpinner from '../../components/IconSpinner/IconSpinner'
import css from './ListingPage.module.css'

const SectionVideoEmbedded = ({url}) => {
  const [isLoading, setLoading] = useState(true);
  return (
    <>
    <div className={css.listingHeroPlayer}>
        {!isLoading && <IconSpinner rootClassName={css.IconSpinner}/>}
        <ReactPlayer onReady={()=>setLoading(false)} url={url} className={css.fieldImage} controls={true}  width={'100%'} height={'100%'}/>
    </div>
    </>
  )
}

export default SectionVideoEmbedded