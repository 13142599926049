import React,{useState} from 'react';
import { ResponsiveImage } from '../../components';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGalleryPlanner = props => {
  const { image } = props;

  return (
    <section className={css.productGallery} data-testid="carousel">
      <img
        src={image}
        alt='gig-img'
        className={css.plannerImg}
      />
    </section>
  );
};

export default SectionGalleryPlanner;
